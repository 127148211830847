body {
  margin: 0%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(to right, rgb(203, 52, 181), rgb(68, 166, 187)) */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.about1{
  width: 33.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.about{
  display: flex;
    flex-direction: row;
    height: 30vh
}

.wrap{
  color: black
}