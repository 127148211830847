
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.theNav{
  background: rgb(0,11,255);
background: linear-gradient(185deg, rgba(0,11,255,1) 0%, rgba(0,140,255,1) 50%, rgba(0,53,97,1) 100%, rgba(0,0,0,0) 100%);
}

.navbar{
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  padding: 0%;
  height: 7vh;
  
}

.navbar a{

  color: #e8e8e8;
  /* font-size: 20px; */
  /* border: 2px solid white; */
  padding: 7px;
  border-radius: 20px;
  text-decoration: none;
}

.navbar a:hover{
  font-size: 25px;
}

.section1{
  display: flex;
  flex-direction: row;
}

.box1{
width: 33.3vw;
display: flex;
justify-content: center;
align-items:  center;
color:white

}

.box2{
  width: 33.3vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.box3{
  width: 33.3vw;
  display: flex;
  justify-content: center;
  align-items:  center;
  color:white
}

li{

  display: flex;
  flex-direction: row;
}

li ul {
  width: 33.3vw;
  display: flex;
  justify-content: center;
  margin: 0%
}

.mainContainer{
  /* background: linear-gradient(to right, rgb(203, 52, 181), rgb(68, 166, 187)) */
  background: #145374;
}

.wrap{
  font-size: 15px;
}


.techContainer{
  height: 75vh;
  background-color: rgb(128, 128, 128);

}



.type{
  height: 25vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.firstIcons{
  /* width: 15vw; */
  display: flex;
  justify-content: space-evenly;
  margin-top: 1%;
  width: 100px;
    display: flex;
    justify-content: space-evenly;
}

.firstIconPics{
  width: 30px;
}

.arrow{
  height: 3vh
}

.stack{
  height:30vh;
  display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.stackBox{
  width: 20vw;
  height: 30vh;
}

/* //3 cards */

.card{
  background: rgba(95, 95, 95, 0.11) !important;
  border-radius: 20px !important;
  border-color: rgba(255, 255, 255, 0.178)!important;
  /* border-width: 2px !important; */
  border: none !important;
  color: white;
  margin-bottom: 30px;
  /* box-shadow: 10px 10px 50px rgba(255, 255, 255, 0.021); */
}

#technologies{
  color: black;
}

.section2{
  background-color: #00334e;
}

.card-body{
  background-color: #5588a3
}

.mb-4{
  margin-bottom: 0%!important; 
}

.myStack{
   margin-top: -5%;
}